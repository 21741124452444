import React from 'react';
import PropTypes from 'prop-types';
import MapPin from '@go1d/go1d/build/components/Icons/Plus';
import Clock from '@go1d/go1d/build/components/Icons/Clock';
import DateRange from '../common/DateRange';
import './EventSession.scss';

export const EventSession = ({
  eventSession,
}) => {
  const { location, startAt, endAt } = eventSession;

  return (
    <div className="event-session">
      <div className="event-session-item event-session-item-location">
        <div className="event-session-item-icon"><MapPin /></div>
        <div>{location.title}</div>
        {!eventSession.isOnline &&
          <div className="txt-grey">
            {[location.thoroughfare, location.locality, location.country].filter(value => !!value).join(', ')}
          </div>
        }
      </div>
      <div className="event-session-item">
        <div className="event-session-item-icon"><Clock /></div>
        <DateRange start={startAt} end={endAt} />
      </div>
    </div>
  );
};

EventSession.propTypes = {
  eventSession: PropTypes.objectOf(PropTypes.any).isRequired,
};
