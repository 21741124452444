import React, { useState, useEffect } from 'react';
import TextMask from 'react-text-mask';
import range from 'lodash/range';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCcVisa, faCcAmex, faCcDiscover, faCcMastercard } from '@fortawesome/free-brands-svg-icons';
import { Alert, Form, FormGroup, Label, Row, Col } from 'reactstrap';
import { Select, TextInput } from '@go1d/go1d';
import { purchaseLo, purchaseLi } from 'src/api';
import { Price } from 'src/components/content/common/Price';
import * as actions from 'src/components/PaymentForm/actions';
import { PaymentContainer, PaymentSubmit } from '../common';

const getMonthOptions = () =>
  range(1, 13).map(item => ({
    value: item,
    label: String(item < 10 ? `0${item}` : item),
  }));

const getYearOptions = () => {
  const d = new Date();
  let year = d.getFullYear();
  return range(year, year + 8).map(item => ({
    value: item,
    label: String(item),
  }));
}

const getCreditMask = () => {
  const group = [/\d/, /\d/, /\d/, /\d/];
  return [...group, ' ', ...group, ' ', ...group, ' ', ...group];
};

const getCVCMask = () => [/\d/, /\d/, /\d/, /\d/];

const CreditCard = ({
  lo,
  user,
  payment,
  eventSessions,
  isAdmin,
  portal,
  appOrigin,
  primaryColor,
  loading,
  paymentApi,
  dispatch
}) => {
  const [errorMsg, setErrorMsg] = useState(null);
  const [isWorking, setIsWorking] = useState(false);

  const monthOptions = getMonthOptions();
  const yearOptions = getYearOptions();
  const creditMask = getCreditMask();
  const cvcMask = getCVCMask();

  const [cardDetails, setCardDetails] = useState({
    cardName: '',
    cardNumber: '',
    cardMonth: '',
    cardYear: '',
    cardCvc: '',
  });

  const getCardInfo = () => ({
    name: cardDetails.cardName,
    number: cardDetails.cardNumber,
    exp_month: cardDetails.cardMonth,
    exp_year: cardDetails.cardYear,
    cvc: cardDetails.cardCvc,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCardDetails({ ...cardDetails, [name]: value });
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v2/';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const purchaseLearning = async () => {
    const bypass = payment.totalAmount === 0;
    const cardInfo = bypass ? null : getCardInfo();
    const userEmails = payment.selectedUsers.map(item => item.mail);

    const purchaseOptions = {
      portal,
      lo,
      cardInfo,
      creditCount: payment.seatCount,
      userEmails,
      eventSessions,
      isAdmin,
      actor: user,
      paymentApi,
    };

    if (payment.coupon) {
      purchaseOptions.coupon = payment.coupon.code;
    }
    return lo.type !== 'course' ? purchaseLi(purchaseOptions) : purchaseLo(purchaseOptions);
  };

  const submitForm = async (event) => {
    event.preventDefault();
    try {
      setIsWorking(true);
      dispatch(actions.purchaseLearningProcessing());

      const enrolmentId = await purchaseLearning();

      dispatch(actions.purchaseLearningSucceeded());
    } catch (ex) {
      setErrorMsg(ex.toString());
      dispatch(actions.purchaseLearningError());
    } finally {
      setIsWorking(false);
    }
  };

  return (
    <Form onSubmit={submitForm}>
      {payment.totalAmount !== 0 && (
        <PaymentContainer>
          <FormGroup>
            <Label for="cardName">
              <FormattedMessage id="app.cardName" defaultMessage="Name on card" />
            </Label>
            <TextInput
              id="cardName"
              name="cardName"
              placeholder={user.name}
              required
              onChange={handleChange}
            />
          </FormGroup>

          <FormGroup>
            <span className="card-icons">
              <FontAwesomeIcon icon={faCcVisa} />
              <FontAwesomeIcon icon={faCcMastercard} />
              <FontAwesomeIcon icon={faCcAmex} />
              <FontAwesomeIcon icon={faCcDiscover} />
              <span className="cc-icon hipercard" />
              <span className="cc-icon elo" />
              <span className="cc-icon aura" />
            </span>
            <Label for="cardNumber">
              <FormattedMessage id="app.cardNumber" defaultMessage="Card number" />
            </Label>
            <TextMask
              id="cardNumber"
              name="cardNumber"
              required
              mask={creditMask}
              placeholder="×××× ×××× ×××× ××××"
              guide={false}
              render={(ref, props) => <TextInput innerRef={ref} {...props} />}
              onChange={handleChange}
            />
          </FormGroup>

          <Row>
            <Col xs="12" md="8">
              <FormGroup className="resetSelectStyle">
                <Label>
                  <FormattedMessage id="app.payment.cardExpiry" defaultMessage="Card expiry" />
                </Label>
                <Row>
                  <Col>
                    <Select
                      name="cardMonth"
                      height="38"
                      defaultText="MM"
                      options={monthOptions}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col>
                    <Select
                      name="cardYear"
                      height="38"
                      defaultText="YYYY"
                      options={yearOptions}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
            <Col xs="12" md="4">
              <FormGroup className="cvv-form-group">
                <Label for="cardCvc">
                  <FormattedMessage id="app.payment.cardCvc" defaultMessage="CVC" />
                </Label>
                <TextMask
                  id="cardCvc"
                  name="cardCvc"
                  placeholder="×××"
                  mask={cvcMask}
                  required
                  guide={false}
                  render={(ref, props) => <TextInput innerRef={ref} {...props} />}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
        </PaymentContainer>
      )}

      {payment.totalAmount === 0 && (
        <PaymentContainer cssProps={{ backgroundColor: 'background', border: 0, css: { boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)', borderRadius: 2 } }}>
          <FormGroup>
            <div className="txt-lg txt-center">
              <FormattedMessage id="app.payment.freeForm.nopayment" defaultMessage="No payment required" />
            </div>
            <div className="txt-sm txt-center txt-grey m-t-sm">
              <FormattedMessage id="app.payment.freeForm.confirm" defaultMessage="Confirm your purchase below" />
            </div>
          </FormGroup>
        </PaymentContainer>
      )}

      <PaymentSubmit disabled={isWorking} primaryColor={primaryColor}>
        {!isWorking && (
          <FormattedMessage
            id="app.payment.purchaseForPrice"
            defaultMessage="Purchase for {price}"
            values={{ price: <Price price={payment.totalAmount} loading currency={payment.currency} /> }}
          />
        )}
        {isWorking && (
          <FormattedMessage id="app.processing" defaultMessage="Processing…" />
        )}
      </PaymentSubmit>

      {errorMsg && (
        <Alert color="danger">
          <FormattedMessage
            id="app.payment.generalFailure"
            defaultMessage={errorMsg}
          />
        </Alert>
      )}
    </Form>
  );
};

export default connect()(CreditCard);
