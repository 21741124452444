import isEmpty from 'lodash/isEmpty';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { fetchUnenrolledUsersToCourse } from '../../../api';

export class SearchUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      isLoading: false,
    };
    this.ref = React.createRef();
  }

  searchUser = async (query) => {
    this.setState({ isLoading: true });
    const { portalName, loId } = this.props;

    const users = await fetchUnenrolledUsersToCourse(portalName, loId, query);
    const options = users.map(user => ({
      id: user._source.id,
      name: user._source.name,
      mail: user._source.mail,
    }));

    this.setState({ isLoading: false, options });
  }

  selectUser = users => {
    const typeahead = this.ref.current;
    const { selectUser } = this.props;

    typeahead.getInstance().clear();
    if (isEmpty(users)) {
      return;
    }

    selectUser(users);
  }

  render() {
    const { renderUser, remainingSeat, placeholder } = this.props;
    const { isLoading, options } = this.state;

    if (!remainingSeat) {
      return <React.Fragment />;
    }

    return (
      <React.Fragment>
        <div className="form-group">
          <span>
            <FormattedMessage
              id="app.content.remainingSeat"
              defaultMessage="Assign {remainingSeat} remaining seat(s)"
              values={{ remainingSeat: <span className="course-assign--remaining-seat">{remainingSeat}</span> }}
            />
          </span>
          <span className="form-group-status">
            <FormattedMessage id="app.content.optional" defaultMessage="Optional" />
          </span>
          <AsyncTypeahead
            id={"id-search-user"}
            renderMenuItemChildren={renderUser}
            onSearch={this.searchUser}
            onChange={this.selectUser}
            isLoading={isLoading}
            disabled={!remainingSeat}
            labelKey="name"
            options={options}
            placeholder={placeholder}
            ref={this.ref}
            highlightOnlyResult
          />
        </div>
        <FormattedMessage
          id="app.content.assignAfterPurchase"
          defaultMessage="You can do this after purchase"
          tagName="div"
        />
      </React.Fragment>
    );
  }
}

SearchUser.propTypes = {
  remainingSeat: PropTypes.number,
  renderUser: PropTypes.func,
  selectUser: PropTypes.func,
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  portalName: PropTypes.string.isRequired,
  loId: PropTypes.number.isRequired,
};

SearchUser.defaultProps = {
  remainingSeat: 0,
  renderUser: (user, options, props) => null,
  selectUser: (user) => null,
  placeholder: 'Enter a name or email...',
};
