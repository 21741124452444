import { requestExchangeRate } from '../api';

export function createFromResponse(blob) {
  return {
    id: Number(blob.id),
    instanceId: Number(blob.instance_id),
    entities: blob.entities,
    userId: Number(blob.user_id),
    title: blob.title,
    code: blob.code,
    type: Number(blob.type) === 1 ? 'percent' : 'amount',
    value: Number(blob.value),
    status: blob.status,
    expiration: new Date(blob.expiration),
    limitation: Number(blob.limitation) || Infinity,
    limitationPerUser: Number(blob.limitation_per_user) || Infinity,
    created: blob.created,
    updated: blob.updated,
    context: {
      usage: {
        all: blob.context.usage.all ? Number(blob.context.usage.all) : 0,
        my: blob.context.usage.all ? Number(blob.context.usage.my) : 0,
      },
    },
  };
}

function isExpired(coupon) {
  if (coupon && coupon.expiration) {
    return Date.now() > coupon.expiration.getTime();
  }
  return false;
}

function isReachUserLimitation(coupon) {
  return coupon.context.usage.my >= coupon.limitationPerUser;
}

function isReachAllLimitation(coupon) {
  return coupon.context.usage.all >= coupon.limitation;
}

function canApplyCouponForLearning(loId, coupon) {
  if (coupon.entities && coupon.entities.lo && coupon.entities.lo.length) {
    const couponEntities = coupon.entities.lo;
    return couponEntities.indexOf(loId) >= 0;
  }

  return true;
}

export function validateCoupon(coupon, loId, currency) {
  if (isExpired(coupon)) {
    return Promise.resolve({
      valid: false,
      error: {
        id: 'app.content.coupon.expired',
        message: 'The coupon is expired',
      },
    });
  }

  if (isReachUserLimitation(coupon)) {
    return Promise.resolve({
      valid: false,
      error: {
        id: 'app.content.coupon.limitedUser',
        message: 'The coupon is reached the limitation per user',
      },
    });
  }

  if (isReachAllLimitation(coupon)) {
    return Promise.resolve({
      valid: false,
      error: {
        id: 'app.content.coupon.limited',
        message: 'The coupon is reached the limitation',
      },
    });
  }

  if (!canApplyCouponForLearning(loId, coupon)) {
    return Promise.resolve({
      valid: false,
      error: {
        id: 'app.content.coupon.wrongLearning',
        message: 'The coupon is not for this learning',
      },
    });
  }

  if (coupon.type === 'amount' && currency !== 'USD') {
    return requestExchangeRate('USD', currency)
      .then(exchangeRate => {
        const value = (coupon.value * exchangeRate.value).toFixed(2);
        return {
          value,
          valid: true,
          title: coupon.title,
          type: coupon.type,
          code: coupon.code,
        };
      });
  }

  return Promise.resolve({
    value: coupon.value,
    valid: true,
    title: coupon.title,
    type: coupon.type,
    code: coupon.code,
  });
}
